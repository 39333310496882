import { Project } from '../types'

import fullSized01 from '../assets/projects/IlluEuropraid2017_1/illuEuropraid2017_1_01_full.gif'
import thumbnail01 from '../assets/projects/IlluEuropraid2017_1/illuEuropraid2017_1_01_thumb.png'
import thumbnailHover01 from '../assets/projects/IlluEuropraid2017_1/illuEuropraid2017_1_01_thumb_hover.png'
import fullSized02 from '../assets/projects/IlluEuropraid2017_1/illuEuropraid2017_1_02_full.png'
import thumbnail02 from '../assets/projects/IlluEuropraid2017_1/illuEuropraid2017_1_02_thumb.png'
import thumbnailHover02 from '../assets/projects/IlluEuropraid2017_1/illuEuropraid2017_1_02_thumb_hover.png'

const project: Project = {
  anchor: 'europraid2017_01',
  backgroundColor: '#ffffff',
  darkMenuText: false,
  description: `Souvenir illustré d’un périple de 23 jours à travers l’Europe.

Eté 2017 : L’histoire de trois nanas & leur voiture rouge.

Les cuisses qui collent aux fauteuils.
Les doigts-knackis qui rotissent sur le volant.
Sous le vent avec Céline Dion.
La 205 qui fume bleu & les abonnés aux toilettes à la turque.

Une aventure en rose bonbon !`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
  ],
  menuGradientColor: '#e03a4e',
  music: {
    label: 'Caravane Palace - Wonderland',
    link: 'https://youtu.be/vCXsRoyFRQE',
    linkColor: '#e03a4e',
    textHoverColor: 'white',
  },
  tags: ['Illustration', 'Voyage', 'Solidarité'],
  title: '205 RAID 2017',
}

export default project
