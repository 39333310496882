import React from 'react'

import Magnifier from 'react-magnifier'
import styled from 'styled-components'

import Section from './Section'

import BackgroundImage from '../assets/images/aboutMeBackground.jpg'

const Background = styled.div`
  align-items: center;
  background-color: #a7977e;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
`

const HelpBox = styled.div`
  background-color: #000000;
  color: white;
  bottom: 20px;
  font-family: 'A Love of Thunder';
  font-size: 12px;
  padding: 1em;
  position: absolute;
  right: 20px;
  z-index: 1;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const CVLink = styled.a`
  font-size: 18px;
  color: #fc3750;

  &: hover {
    text-decoration: underline;
  }
`

const MagnifierContainer = styled.div`
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (1080 / 1920) * 100%;
  
  &> .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

function About() {
  return (
    <Section>
      <HelpBox>
        Utilisez <span style={{ fontSize: '18px' }}>la loupe</span> et/ou
        Téléchargez
        <CVLink
          href={`${process.env.PUBLIC_URL}/CV2019-allouiscaro.pdf`}
          target="_blank"
        >
          mon CV
        </CVLink>
      </HelpBox>
      <Background>
        <MagnifierContainer>
          <Magnifier
            className="inner"
            src={BackgroundImage}
            mgWidth={200}
            mgHeight={200}
            mgShowOverflow={false}
            zoomFactor={1}
          />
        </MagnifierContainer>
      </Background>
    </Section>
  )
}

export default About
