import React from 'react'

import styled from 'styled-components'

import Section from './Section'

import BackgroundImage from '../assets/images/contactBackground.jpg'
import ImageCadre from '../assets/images/cadrePhoto.gif'
import BottleImage from '../assets/images/contactBouteille.png'
import BottleHoverImage from '../assets/images/contactBouteille_hover.png'

const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  padding: 2em 2em 2em calc(2em + 70px);
  width: 100%;
`

const Frame = styled.img`
  position: absolute;
  top: calc(50% - 150px);
  width: 540px;
`

const Bottle = styled.div`
  background-image: url(${BottleImage});
  background-size: cover;
  height: 293px;
  position: absolute;
  left: calc(50% - 270px);
  width: 700px;
  top: calc(50% + 50px);

  &: hover {
    background-image: url(${BottleHoverImage});
    cursor: pointer;
  }
`
const Copyright = styled.p`
  position: absolute;
  left: calc(50% - 61px);
  bottom: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Consola';

  &:hover {
    &:after {
      color: #e03a4e;
      content: ' <3';
    }
  }
`

function Contact() {
  return (
    <Section>
      <Container>
        <Frame src={ImageCadre} />
        <a href="mailto:caroline.allouis@gmail.com">
          <Bottle />
        </a>
        <Copyright>&copy;2019 Kakine</Copyright>
      </Container>
    </Section>
  )
}

export default Contact
