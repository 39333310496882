import React from 'react'

import styled from 'styled-components'

interface StyledSectionProps {
  backgroundColor: string
}

const StyledSection = styled.div<StyledSectionProps>`
  background-color: ${props => props.backgroundColor};
`

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

interface SectionProps {
  backgroundColor?: string
  children?: React.ReactNode
  className?: string
}

function Section({ backgroundColor, children, className }: SectionProps) {
  return (
    <StyledSection
      backgroundColor={backgroundColor || 'white'}
      className={`section ${className}`}
    >
      <Container>{children}</Container>
    </StyledSection>
  )
}

export default Section
