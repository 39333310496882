import { Project } from '../types'

import fullSized01 from '../assets/projects/IlluEuropraid2017_2/illuEuropraid2017_2_01_full.gif'
import thumbnail01 from '../assets/projects/IlluEuropraid2017_2/illuEuropraid2017_2_01_thumb.png'
import thumbnailHover01 from '../assets/projects/IlluEuropraid2017_2/illuEuropraid2017_2_01_thumb_hover.png'
import fullSized02 from '../assets/projects/IlluEuropraid2017_2/illuEuropraid2017_2_02_full.png'
import thumbnail02 from '../assets/projects/IlluEuropraid2017_2/illuEuropraid2017_2_02_thumb.png'
import thumbnailHover02 from '../assets/projects/IlluEuropraid2017_2/illuEuropraid2017_2_02_thumb_hover.png'

const project: Project = {
  anchor: 'europraid2017_02',
  backgroundColor: '#DDF6DE',
  darkMenuText: true,
  description: `Eté 2017, souvenir sucré de l'escale autrichienne lors d'un périple de 23 jours à travers l’Europe.

Des couleurs vives pour cet instant à la fois joyeux et estival. Et de la glace, plein de glace !`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
  ],
  menuGradientColor: '#ffffff',
  music: {
    label: 'Dirty Dancing - The time of my life',
    link: 'https://youtu.be/l9BbUqHrWFI',
    linkColor: '#D3DC1E',
  },
  tags: ['Illustration', 'Voyage', 'Solidarité'],
  title: 'Autriche glacée',
}

export default project
