import { Project } from '../types'

import fullSized01 from '../assets/projects/Burrito/burrito_01_full.gif'
import thumbnail01 from '../assets/projects/Burrito/burrito_01_thumb.png'
import thumbnailHover01 from '../assets/projects/Burrito/burrito_01_thumb_hover.png'
import fullSized02 from '../assets/projects/Burrito/burrito_02_full.jpg'
import thumbnail02 from '../assets/projects/Burrito/burrito_02_thumb.png'
import thumbnailHover02 from '../assets/projects/Burrito/burrito_02_thumb_hover.png'
import fullSized03 from '../assets/projects/Burrito/burrito_03_full.png'
import thumbnail03 from '../assets/projects/Burrito/burrito_03_thumb.png'
import thumbnailHover03 from '../assets/projects/Burrito/burrito_03_thumb_hover.png'

const project: Project = {
  anchor: 'chidoBurrito',
  backgroundColor: '#DDD6C7',
  darkMenuText: false,
  description: `Sympathique petit restaurant mexicain où burritos, quesadillas et tacos sont les plats-rois !

Ici, l'ambiance est colorée, festive et conviviale : enseigne, stickers et menuboards s'habillent de couleurs chaudes.

Arribaaa !`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
  ],
  menuGradientColor: '#9C2E1E',
  music: {
    label: 'Pat Boone - Speedy Gonzales',
    link: 'https://youtu.be/v4s2AMKPHnE',
    linkColor: '#9C2E1E',
    textHoverColor: 'white',
  },
  tags: ['Habillage intérieur', 'Enseigne', 'Stickers', 'Menuboards'],
  title: 'Chido Burrito',
}

export default project
