import { Project } from '../types'

import fullSized01 from '../assets/projects/Medocs/medocs_01_full.gif'
import thumbnail01 from '../assets/projects/Medocs/medocs_01_thumb.png'
import thumbnailHover01 from '../assets/projects/Medocs/medocs_01_thumb_hover.png'
import fullSized02 from '../assets/projects/Medocs/medocs_02_full.png'
import thumbnail02 from '../assets/projects/Medocs/medocs_02_thumb.png'
import thumbnailHover02 from '../assets/projects/Medocs/medocs_02_thumb_hover.png'
import fullSized03 from '../assets/projects/Medocs/medocs_03_full.png'
import thumbnail03 from '../assets/projects/Medocs/medocs_03_thumb.png'
import thumbnailHover03 from '../assets/projects/Medocs/medocs_03_thumb_hover.png'
import fullSized04 from '../assets/projects/Medocs/medocs_04_full.jpg'
import thumbnail04 from '../assets/projects/Medocs/medocs_04_thumb.png'
import thumbnailHover04 from '../assets/projects/Medocs/medocs_04_thumb_hover.png'

const project: Project = {
  anchor: 'medicaments',
  backgroundColor: '#D9EAE5',
  darkMenuText: false,
  description: `Savon ultra doux, canard flotteur, brosses à dents, dentifrice Parodontax, brosse à cheveux, brumisateur Xerolan, serviettes éponges... et une affiche Aquafresh, bien évidemment (?).
Projets dans le domaine du pharmaceutique que j'ai réalisés de A à Z en agence.

Pour Parodontax et Xerolan, on est sur des compositions épurées avec un maximum de deux couleurs, qui attirent tout de suite l'oeil, connotées santé et bien-être.

Pour Aquafresh, on est beaucoup plus dans le pep's, la couleur et la sympathie du client : la marque convoite le jeune et le fun.

Et puis, le vert, ça se veut bio, écolo, teinte plante feuillue... La couleur de l'avenir !`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
    {
      fullSized: fullSized04,
      thumbnail: thumbnail04,
      thumbnailHover: thumbnailHover04,
    },
  ],
  menuGradientColor: '#63BC9B',
  music: {
    label: `Ben le Koala - Brosse-toi les dents`,
    link: 'https://youtu.be/cwA8K8vHLgs',
    linkColor: '#63BC9B',
  },
  tags: ['Packaging', 'Conception', 'mise en page', 'Print'],
  title: `Dans ma salle de bain,
il y a`,
}

export default project
