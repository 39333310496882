import { Project } from '../types'

import fullSized01 from '../assets/projects/AfficheEmily/Emily01_full.gif'
import thumbnail01 from '../assets/projects/AfficheEmily/Emily01_thumb.png'
import thumbnailHover01 from '../assets/projects/AfficheEmily/Emily01_thumb_hover.png'
import fullSized02 from '../assets/projects/AfficheEmily/Emily02_full.png'
import thumbnail02 from '../assets/projects/AfficheEmily/Emily02_thumb.png'
import thumbnailHover02 from '../assets/projects/AfficheEmily/Emily02_thumb_hover.png'

const project: Project = {
  anchor: 'ombreEmily',
  backgroundColor: '#bbbbbb',
  darkMenuText: false,
  description: `Affiche illustrée du magnifique film réalisé par Paul Feig, l'Ombre d'Emily.

Deux personnages attachants, opposés, qui se rapprochent dangereusement. Voire trop.
Mécanisme bien rodé qui nous fait constamment changer de parti : la gentille mignonne un peu godiche aux tendances malsaines ou la confiante qui éblouit son public au passé houleux presque honteux.
Les cachotteries passées refont surface et entraînent avec elles quelques cadavres.

Bref, comme le film le dit si bien : nous avons tous des secrets.`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
  ],
  menuGradientColor: '#333',
  music: {
    label: 'Fine Young Cannibals - She drives me crazy',
    link: 'https://youtu.be/UtvmTu4zAMg',
    linkColor: '#4CEFEE',
  },
  tags: ['Affiche', 'Illustration', 'Cinéma', 'Mystere'],
  title: 'Secret-ception',
}

export default project
