import React from 'react'

import Slider from 'react-slick'
import styled from 'styled-components'

import { Project } from '../types'
import MusicLink from './MusicLink'

interface ContainerProps {
  textColor?: string
}

const Container = styled.div<ContainerProps>`
  box-sizing: border-box;
  color: ${props => props.textColor || '#333'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
  width: 100%;
`

const Title = styled.div`
  font-family: 'Kraft Nine';
  font-size: 60px;
  white-space: pre-wrap;
`

const TagsLine = styled.div`
  font-family: 'A Love of Thunder';
  font-size: 16px;
  letter-spacing: 2pt;
  margin-bottom: 40px;
`

const Text = styled.p`
  font-family: 'Consola';
  font-size: 14px;
  margin: 0 0 20px 0;
  max-width: 100%;
  text-align: left;
  white-space: pre-wrap;
`

const ThumbnailsSlider = styled(Slider)`
  width: 100%;
`

interface ThumbnailProps {
  image: string
  imageHover: string
}

const Thumbnail = styled.div<ThumbnailProps>`
  display: block;
  height: 200px;
  width: 200px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.image});

  &:hover {
    background-image: url(${props => props.imageHover});
    cursor: pointer;
  }
`

interface DescriptionProps {
  project: Project
  selectedImage: number
  onImageSelection: (index: number) => void
}

function Description(props: DescriptionProps) {
  const { onImageSelection, project } = props
  const { description, images, music, tags, textColor, title } = project
  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: false,
    responsive: [
      { breakpoint: 860, settings: { slidesToShow: 4, swipe: true } },
      { breakpoint: 640, settings: { slidesToShow: 3, swipe: true } },
      { breakpoint: 420, settings: { slidesToShow: 2, swipe: true } },
      { breakpoint: 200, settings: { slidesToShow: 1, swipe: true } },
    ],
    slidesToShow: 3,
    swipe: true,
  }

  return (
    <Container textColor={textColor}>
      <Title>{title}</Title>
      <TagsLine>{tags.join(' | ')}</TagsLine>
      <Text>{description}</Text>
      {music && <MusicLink {...music} />}
      <div style={{ width: '100%' }}>
        <ThumbnailsSlider {...sliderSettings}>
          {images.map(({ thumbnail, thumbnailHover }, index) => (
            <div key={index}>
              <Thumbnail
                image={thumbnail}
                imageHover={thumbnailHover}
                onClick={() => onImageSelection(index)}
              />
            </div>
          ))}
        </ThumbnailsSlider>
      </div>
    </Container>
  )
}

export default Description
