import { Project } from '../types'

import fullSized01 from '../assets/projects/Voiture/voiture_01_full.jpg'
import thumbnail01 from '../assets/projects/Voiture/voiture_01_thumb.png'
import thumbnailHover01 from '../assets/projects/Voiture/voiture_01_thumb_hover.png'
import fullSized02 from '../assets/projects/Voiture/voiture_02_full.jpg'
import thumbnail02 from '../assets/projects/Voiture/voiture_02_thumb.png'
import thumbnailHover02 from '../assets/projects/Voiture/voiture_02_thumb_hover.png'
import fullSized03 from '../assets/projects/Voiture/voiture_03_full.gif'
import thumbnail03 from '../assets/projects/Voiture/voiture_03_thumb.png'
import thumbnailHover03 from '../assets/projects/Voiture/voiture_03_thumb_hover.png'

const project: Project = {
  anchor: 'ladyInRaid',
  backgroundColor: '#D6F1F5',
  darkMenuText: false,
  description: `"The lady in red is dancing with me, cheek to cheek
There's nobody here, it's just you and me
It's where I want to be
But I hardly know this beauty by my side (...)" ~ Chris De Burgh

On la connait tous... Enfin, j'espère. Personnellement, c'est un classique, mais ce n'est que mon humble avis.

Voilà là l'origine du nom de mon association de coeur. Groupe de trois nanas, Lady in Raid est partie à l'assaut de l'Europe deux étés de suite, et ce, pendant trois semaines.
Qui dit tour d'Europe, dit transport. Qui dit transport, dit voiture.
Je vous présente donc, la belle, l'unique, Lady : sublime 205 rouge au confort incomparable et au look totalement girly-peps.
Stickers en mode super nanas sortis tout droit de mon cerveau, fichiers EXE préparés pour l'impression et voici -TADA !- la titine !`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
  ],
  menuGradientColor: '#FF104A',
  music: {
    label: 'Donuts & Swann - Hit the road Jack',
    link: 'https://youtu.be/-FKMkg3V9cI',
    linkColor: '#00B69A',
    textHoverColor: '#063E4A',
  },
  tags: ['Stickers', 'Voyage', 'Voiture', 'Assiociation'],
  title: 'Lady in Raid',
}

export default project
