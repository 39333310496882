import { Project } from '../types'

import fullSized01 from '../assets/projects/BoitesTupp/boitesTupp_01_full.gif'
import thumbnail01 from '../assets/projects/BoitesTupp/boitesTupp_01_thumb.png'
import thumbnailHover01 from '../assets/projects/BoitesTupp/boitesTupp_01_thumb_hover.png'
import fullSized02 from '../assets/projects/BoitesTupp/boitesTupp_02_full.png'
import thumbnail02 from '../assets/projects/BoitesTupp/boitesTupp_02_thumb.png'
import thumbnailHover02 from '../assets/projects/BoitesTupp/boitesTupp_02_thumb_hover.png'

const project: Project = {
  anchor: 'boitesTupperware',
  backgroundColor: 'white',
  darkMenuText: false,
  description: `Pâques arrivant, les fameuses boîtes Tupperware se sont refaite une beauté.

Illustrations mignonnes mélangeant couleurs vives et teintes pastel du printemps, lapins, oeufs et poules de sortie, on ne peut pas faire plus Pâques que cela.
  
Pour vos petits plats, laquelle choisirez-vous ?`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
  ],
  menuGradientColor: '#8EC6D1',
  music: {
    label: 'Chantal Goya - Un lapin',
    link: 'https://youtu.be/eD-sqt5rA9g',
    linkColor: '#8EC6D1',
  },
  tags: ['Packaging', 'Illustration', 'Fabrication'],
  title: 'Boites hautes en couleur',
}

export default project
