import { Project } from '../types'

import fullSized01 from '../assets/projects/IllusCigarettes/cigarettes_01_full.gif'
import thumbnail01 from '../assets/projects/IllusCigarettes/cigarettes_01_thumb.png'
import thumbnailHover01 from '../assets/projects/IllusCigarettes/cigarettes_01_thumb_hover.png'
import fullSized02 from '../assets/projects/IllusCigarettes/cigarettes_02_full.png'
import thumbnail02 from '../assets/projects/IllusCigarettes/cigarettes_02_thumb.png'
import thumbnailHover02 from '../assets/projects/IllusCigarettes/cigarettes_02_thumb_hover.png'
import fullSized03 from '../assets/projects/IllusCigarettes/cigarettes_03_full.png'
import thumbnail03 from '../assets/projects/IllusCigarettes/cigarettes_03_thumb.png'
import thumbnailHover03 from '../assets/projects/IllusCigarettes/cigarettes_03_thumb_hover.png'
import fullSized04 from '../assets/projects/IllusCigarettes/cigarettes_04_full.png'
import thumbnail04 from '../assets/projects/IllusCigarettes/cigarettes_04_thumb.png'
import thumbnailHover04 from '../assets/projects/IllusCigarettes/cigarettes_04_thumb_hover.png'

const project: Project = {
  anchor: 'cigarettes',
  backgroundColor: '#B4B4B4',
  darkMenuText: false,
  description: `Campagne contre la cigarette proposé par le CNCT ciblant les jeunes dans la rue.

La conception se veut directe et visible :
  - des couleurs vives pour attirer le regard
  - des mots forts voire choquants
  - un thème qui captive les intéressés de la vingtaine : le sexe.

Que dire de plus...`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
    {
      fullSized: fullSized04,
      thumbnail: thumbnail04,
      thumbnailHover: thumbnailHover04,
    },
  ],
  menuGradientColor: '#333',
  music: {
    label: `Bee Gees - Stayin' Alive`,
    link: 'https://youtu.be/fNFzfwLM72c',
    linkColor: '#92FEFC',
  },
  tags: ['Campagne', 'Affiches', 'CNCT'],
  title: `La cigarette tue,
essaie donc la pipe`,
}

export default project
