import { Project } from '../types'

import fullSized01 from '../assets/projects/PremiereFois/premiereFois_01_full.gif'
import thumbnail01 from '../assets/projects/PremiereFois/premiereFois_01_thumb.png'
import thumbnailHover01 from '../assets/projects/PremiereFois/premiereFois_01_thumb_hover.png'

const project: Project = {
  anchor: 'premiereFois',
  backgroundColor: '#DDFFFD',
  darkMenuText: false,
  description: `"La vie n'est qu'une série de premières fois."

C'est sur cette douce moralité qu'une poignée d'amis gribouilleurs et moi-même avons décidé de s'exprimer dans un petit livre rien qu'à nous.
Avec humour gras, couleurs gaies et tracés vivants, voici la première fois que j'ai choisie.

Et vous, quelle est la vôtre ?`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
  ],
  menuGradientColor: '#F99375',
  music: {
    label: `Claude-Francois - Comme d'habitude`,
    link: 'https://youtu.be/GME3fMeK5ts',
    linkColor: '#F99375',
    textHoverColor: 'white',
  },
  tags: ['Livre', 'Illustration', 'Collectif'],
  title: 'Ma première fois',
}

export default project
