import React from 'react'

import styled from 'styled-components'

import Licorne from '../../assets/images/licorneConstruction.png'
import Kakine from '../../assets/images/titreCV.png'

const Container = styled.div`
  align-content: center;
  box-sizing: border-box;
  display: flex;
  font-family: 'A Love of Thunder';
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: scroll;
  padding: 1em;
  text-align: center;
`

const Button = styled.div`
  background-color: #fc3750;
  border-radius: 25px;
  color: white;
  display: block;
  margin: auto;
  padding: 15px 25px;
  width: fit-content;
`

function UnderConstruction() {
  return (
    <Container>
      <div>
        <span style={{ fontSize: '3em' }}>Hello !</span>
        <img src={Kakine} style={{ width: '100%' }} />
      </div>
      <span style={{ fontSize: '1.5em' }}>
        Helas, la version mobile n'est pas encore prete...
      </span>
      <br />
      <img src={Licorne} style={{ width: 'fit-content', margin: '0 auto' }} />
      <br />
      <p style={{ fontSize: '1.5em', marginBottom: 0 }}>
        Vous pouvez ouvrir cette page sur votre ordinateur
      </p>
      <span style={{ fontSize: '1em' }}>
        (Ca marche tres bien et c'est super joli)
      </span>
      <p style={{ fontSize: '1.5em' }}>et/ou</p>
      <a href={`${process.env.PUBLIC_URL}/CV2019-allouiscaro.pdf`}>
        <Button>Télécharger mon CV</Button>
      </a>
    </Container>
  )
}

export default UnderConstruction
