import Emily from './Emily'
import IlluEuropraid2017_1 from './IlluEuropraid2017_1'
import IlluEuropraid2017_2 from './IlluEuropraid2017_2'
import IlluEuropraid2018 from './IlluEuropraid2018'
import LoupsGarous from './LoupsGarous'
import IllusCigarettes from './IllusCigarettes'
import PremiereFois from './PremiereFois'
import Voiture from './Voiture'
import JdrManoir from './JdrManoir'
import Bieres from './Bieres'
import Medocs from './Medocs'
import Burrito from './Burrito'
import MagazineTupp from './MagazineTupp'
import BoitesTupp from './BoitesTupp'
import Kinkhao from './Kinkhao'
import PepeManzo from './PepeManzo'
import Mouton from './Mouton'

export default [
  IlluEuropraid2017_1,
  Emily,
  Voiture,
  Medocs,
  IlluEuropraid2018,
  LoupsGarous,
  Burrito,
  IlluEuropraid2017_2,
  IllusCigarettes,
  Bieres,
  PremiereFois,
  JdrManoir,
  PepeManzo,
  MagazineTupp,
  Mouton,
  BoitesTupp,
  Kinkhao,
]
