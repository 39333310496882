import React from 'react'

import styled from 'styled-components'

import { Project } from '../types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface ViewerProps {
  project: Project
  selectedImage: number
}

function Viewer(props: ViewerProps) {
  const { project, selectedImage } = props
  const displayedImage = project.images[selectedImage].fullSized

  return (
    <Container>
      <img src={displayedImage} style={{ maxWidth: '100%' }} />
    </Container>
  )
}

export default Viewer
