import { Project } from '../types'

import fullSized01 from '../assets/projects/Mouton/mouton_01_full.gif'
import thumbnail01 from '../assets/projects/Mouton/mouton_01_thumb.png'
import thumbnailHover01 from '../assets/projects/Mouton/mouton_01_thumb_hover.png'
import fullSized02 from '../assets/projects/Mouton/mouton_02_full.jpg'
import thumbnail02 from '../assets/projects/Mouton/mouton_02_thumb.png'
import thumbnailHover02 from '../assets/projects/Mouton/mouton_02_thumb_hover.png'
import fullSized03 from '../assets/projects/Mouton/mouton_03_full.jpg'
import thumbnail03 from '../assets/projects/Mouton/mouton_03_thumb.png'
import thumbnailHover03 from '../assets/projects/Mouton/mouton_03_thumb_hover.png'
import fullSized04 from '../assets/projects/Mouton/mouton_04_full.png'
import thumbnail04 from '../assets/projects/Mouton/mouton_04_thumb.png'
import thumbnailHover04 from '../assets/projects/Mouton/mouton_04_thumb_hover.png'

const project: Project = {
  anchor: 'designeMoiUnMouton',
  backgroundColor: '#FBF2EC',
  darkMenuText: false,
  description: `Aujourd'hui, le jeu de société occupe une grande place dans notre vie. Rassembler, communiquer & rire sont essentiels et ont fait de lui un incontournable que ce soit entre amis ou en famille.
Malheureusement, il reste encore inaccessible ou peu adapté à quelques minorités, comme les malentendants par exemple. Le langage des signes n'étant pas inné à comprendre pour une personne entendante comme nous, jouer devient alors très compliqué !

C'est pourquoi, je vous invite à découvrir Dé-signe-moi un mouton, jeu qui vise à faciliter la communication entre entendants et malentendants : interdiction de parler, le but est d'aider son partenaire à reproduire le visuel que l'on a devant nous, et ce, dans le temps imparti.

Adapter son langage pour se faire comprendre de l'autre : drôle, pas si simple, mais une expérience terriblement riche !

Bien sûr, qui dit entendant vs malendant, dit aussi entendant vs entendant et malentendants vs malentendants !

A vos moutons, partez !`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
    {
      fullSized: fullSized04,
      thumbnail: thumbnail04,
      thumbnailHover: thumbnailHover04,
    },
  ],
  menuGradientColor: '#34728C',
  music: {
    label: `No Doubt - Don't speak`,
    link: 'https://youtu.be/TR3Vdo5etCQ',
    linkColor: '#E24243',
    textHoverColor: 'white',
  },
  tags: ['Jeu (2 joueurs)', 'Conception', 'Illustration', 'Société'],
  title: `Dé-signe moi un mouton`,
}

export default project
