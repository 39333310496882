import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  font-family: consola;
  font-size: 14px;
  margin-bottom: 40px;
`

interface LinkProps {
  linkColor: string
  textColor?: string
  textHoverColor?: string
}

const Link = styled.a<LinkProps>`
  color: ${props => props.textColor || '#333'};
  font-family: 'A Love of Thunder';
  background: ${props =>
    `linear-gradient(to bottom, ${props.linkColor} 0%, ${
      props.linkColor
    } 100%)`};
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 3px;
  transition: all 0.2s;
  padding: 2px;

  &:hover {
    background-size: 4px 22px;
    color: ${props => props.textHoverColor || '#333'};
  }
`

interface MusicLinkProps {
  label: string
  link: string
  linkColor: string
  textColor?: string
  textHoverColor?: string
}

function MusicLink(props: MusicLinkProps) {
  const { label, link, linkColor, textColor, textHoverColor } = props

  return (
    <Container>
      <Link
        href={link}
        target="_blank"
        linkColor={linkColor}
        textColor={textColor}
        textHoverColor={textHoverColor}
      >
        <i className="fas fa-headphones" />
        &nbsp;
        {label}
      </Link>
    </Container>
  )
}

export default MusicLink
