import { Project } from '../types'

import fullSized01 from '../assets/projects/Bieres/bieres_01_full.gif'
import thumbnail01 from '../assets/projects/Bieres/bieres_01_thumb.png'
import thumbnailHover01 from '../assets/projects/Bieres/bieres_01_thumb_hover.png'

const project: Project = {
  anchor: 'brasserieDuPilat',
  backgroundColor: '#EDEDEA',
  darkMenuText: false,
  description: `Une petite mousse ?
La Brasserie du Pilat désirait renouveler les étiquettes de ses bières phares.

La Madmax, bière ambrée, forte en goût, robe miel qui fait vite monter le rouge aux joues.
La Rouquine, douce et relevée à la fois, les malts caramels lui donnent cette belle teinte chaude. Hauts les coeurs !
Enfin, la Veuve noire, comme son nom l'indique, elle piquouille... Brun acajou allant au noir ébène, elle a un charme fou pour ravir le palais.

Pour quelle femme fatale craquerez vous ?`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
  ],
  menuGradientColor: '#3799AD',
  music: {
    label: `Jean-Luc Lahaye - Femme que j'aime`,
    link: 'https://youtu.be/A9k3JcrVg2U',
    linkColor: '#3799AD',
    textHoverColor: 'white',
  },
  tags: ['Packaging', 'Illustration', 'Mise en page'],
  title: 'La brasserie du Pilat',
}

export default project
