import React from 'react'

import chunk from 'lodash.chunk'
import ReactResizeDetector from 'react-resize-detector'
import Slider from 'react-slick'
import styled from 'styled-components'

import HomeIcon from '../assets/images/home.png'
import HomeIconHover from '../assets/images/home_hover.png'
import AboutIcon from '../assets/images/about.png'
import AboutIconHover from '../assets/images/about_hover.png'
import ContactIcon from '../assets/images/contact.png'
import ContactIconHover from '../assets/images/contact_hover.png'
import ProjectsIcon from '../assets/images/projets.png'
import ProjectsIconHover from '../assets/images/projets_hover.png'

const Container = styled.div`
  max-height: 100vh;
  width: 60px;
`

const ProjectsContainer = styled.div`
  height: calc(100vh - (4 * 60px) - (32px * 2) - 10px);
`

interface MenuIconProps {
  icon: string
  iconHover: string
}

const MenuIcon = styled.div<MenuIconProps>`
  display: block;
  height: 50px;
  width: 60px;
  margin: 0 auto 10px auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.icon});

  &.active {
    background-image: url(${props => props.iconHover});
  }

  &:hover {
    background-image: url(${props => props.iconHover});
    cursor: pointer;
  }
`

interface MenuTextProps {
  darkText: boolean
}

const MenuText = styled.div<MenuTextProps>`
  color: ${props =>
    props.darkText ? 'rgba(51,48,0, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  font-size: 2em;
  width: 60px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  text-align: center;
  transition: font-size 0.3s ease-in-out;

  &.active {
    color: ${props => (props.darkText ? '#333' : 'white')};
    font-size: 3em;
  }

  &:hover {
    color: ${props => (props.darkText ? '#333' : 'white')};
    cursor: pointer;
  }
`

interface NavButtonProps {
  darkText?: boolean
}

const NavButton = styled.i<NavButtonProps>`
  color: ${props =>
    props.darkText ? 'rgba(51,48,0, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  font-size: 1em;
  width: 60px;
  text-align: center;

  &: hover {
    color: ${props => (props.darkText ? '#333' : 'white')};
    cursor: pointer;
  }
`

const addLeadingZero = (value: number) => `${value < 10 ? '0' : ''}${value}`

interface MenuProps {
  activeIndex: number
  darkText: boolean
  projectsAnchors: string[]
}

class Menu extends React.Component<MenuProps> {
  sliderContainer = React.createRef<HTMLDivElement>()
  slider = React.createRef<Slider>()

  slidesToShow = 1

  public goToProject(project: number) {
    const { projectsAnchors } = this.props
    const chunks = chunk(
      projectsAnchors.map((value, index) => index),
      this.slidesToShow
    )
    chunks.forEach((chunk, chunkIndex) => {
      if (chunk.includes(project)) {
        this.slider.current && this.slider.current.slickGoTo(chunkIndex)
      }
    })
  }

  render() {
    const { activeIndex, darkText, projectsAnchors } = this.props

    return (
      <Container>
        <a href="#home">
          <MenuIcon
            className={activeIndex === 0 ? 'active' : ''}
            icon={HomeIcon}
            iconHover={HomeIconHover}
          />
        </a>
        <a href="#about">
          <MenuIcon
            className={activeIndex === 1 ? 'active' : ''}
            icon={AboutIcon}
            iconHover={AboutIconHover}
          />
        </a>
        <MenuIcon icon={ProjectsIcon} iconHover={ProjectsIconHover} />
        <NavButton
          className="fas fa-chevron-up"
          darkText={darkText}
          onClick={() => this.slider.current && this.slider.current.slickPrev()}
        />
        <ProjectsContainer ref={this.sliderContainer}>
          <ReactResizeDetector handleHeight>
            {({ height }: { height: number }) => {
              if (!height) return <div />
              const slidesToShow = Math.round(height / 52)
              this.slidesToShow = slidesToShow
              const chunks = chunk(projectsAnchors, slidesToShow)

              return (
                <div key="toto">
                  <Slider
                    vertical
                    arrows={false}
                    infinite={false}
                    ref={this.slider}
                    slidesToScroll={1}
                    slidesToShow={1}
                    slide={'div'}
                  >
                    {chunks.map((chunk, chunkIndex) => (
                      <div key={chunkIndex}>
                        {chunk.map((anchor, anchorIndex) => (
                          <a key={anchorIndex} href={`#${anchor}`}>
                            <MenuText
                              className={
                                activeIndex ===
                                2 + (slidesToShow * chunkIndex + anchorIndex)
                                  ? 'active'
                                  : ''
                              }
                              darkText={darkText}
                            >
                              {addLeadingZero(
                                slidesToShow * chunkIndex + anchorIndex + 1
                              )}
                            </MenuText>
                          </a>
                        ))}
                      </div>
                    ))}
                  </Slider>
                </div>
              )
            }}
          </ReactResizeDetector>
        </ProjectsContainer>
        <NavButton
          className="fas fa-chevron-down"
          darkText={darkText}
          onClick={() => this.slider.current && this.slider.current.slickNext()}
        />
        <a href="#contact">
          <MenuIcon
            className={
              activeIndex === 2 + projectsAnchors.length ? 'active' : ''
            }
            icon={ContactIcon}
            iconHover={ContactIconHover}
          />
        </a>
      </Container>
    )
  }
}

export default Menu
