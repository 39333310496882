import React from 'react'

import styled from 'styled-components'

import Section from './Section'

import BackgroundImage from '../assets/images/accueilBackground.jpg'
import AnimationImage from '../assets/images/accueilAnim.gif'

const Background = styled.div`
  align-items: center;
  background-image: url(${BackgroundImage});
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const Animation = styled.img`
  transform: rotate(10deg);
  width: 260px;
`

const StyledSection = styled(Section)`
  z-index: 7;
`

function Welcome() {
  return (
    <StyledSection>
      <Background>
        <Animation src={AnimationImage} alt="Animation de bienvenue" />
      </Background>
    </StyledSection>
  )
}

export default Welcome
