import React from 'react'

import styled from 'styled-components'

interface ContainerProps {
  startColor: string,
}

const Container = styled.div<ContainerProps>`
  background: linear-gradient(
    ${props => props.startColor},
    ${props => props.startColor},
    transparent
  );
  box-sizing: border-box;
  color: white;
  padding: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  transition: background 2s ease-in-out;
  z-index: 50;
`

interface LeftBarProps {
  children?: React.ReactNode,
  className?: string,
  startColor: string,
}

function LeftBar(props: LeftBarProps) {
  const { children, className, startColor } = props
  return (
    <Container startColor={startColor} className={className}>
      {children}
    </Container>
  )
}

export default LeftBar
