import React from 'react'
import ReactDOM from 'react-dom'

import ReactGA from 'react-ga'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-70908222-3')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
