import React, { useState, useRef } from 'react'

// @ts-ignore
import ReactFullPage from '@fullpage/react-fullpage'
import ReactGA from 'react-ga'

import About from '../components/About'
import Contact from '../components/Contact'
import LeftBar from '../components/LeftBar'
import Menu from '../components/Menu'
import Welcome from '../components/Welcome'

import projects from '../projects'
import ProjectSection from '../components/ProjectSection'
import LazyLoad from 'react-lazyload'

const HOME_GRADIENT = '#3D6D65'
const ABOUT_GRADIENT = '#8B8B8B'
const CONTACT_GRADIENT = '#779C8F'

function Home() {
  const menu = useRef<Menu>(null)

  const projectsAnchors = projects.map(({ anchor }) => anchor)
  const anchors = ['home', 'about', ...projectsAnchors, 'contact']
  const menuDarkTexts = [
    false,
    false,
    ...projects.map(({ darkMenuText }) => darkMenuText),
    false,
  ]
  const menuGradients = [
    HOME_GRADIENT,
    ABOUT_GRADIENT,
    ...projects.map(({ menuGradientColor }) => menuGradientColor),
    CONTACT_GRADIENT,
  ]
  const [menuIndex, setMenuIndex] = useState(0)

  return (
    <>
      <LazyLoad placeholder={<div>Loading</div>}>
        <LeftBar startColor={menuGradients[menuIndex]}>
          <Menu
            darkText={menuDarkTexts[menuIndex]}
            activeIndex={menuIndex}
            projectsAnchors={projectsAnchors}
            ref={menu}
          />
        </LeftBar>
        <ReactFullPage
          anchors={anchors}
          lazyLoad
          // @ts-ignore
          onLeave={(origin, destination) => {
            ReactGA.pageview(
              `${window.location.pathname}${window.location.search}${
                anchors[destination.index]
              }`
            )
            setMenuIndex(destination.index)
            menu.current && menu.current.goToProject(destination.index - 2)
          }}
          render={() => {
            return (
              <ReactFullPage.Wrapper>
                <Welcome />
                <About />
                {projects.map(project => (
                  <ProjectSection key={project.anchor} project={project} />
                ))}
                <Contact />
              </ReactFullPage.Wrapper>
            )
          }}
        />
      </LazyLoad>
    </>
  )
}

export default Home
