import React, { useState } from 'react'

import styled from 'styled-components'

import Description from './Description'
import Section from './Section'
import Viewer from './Viewer'
import { Project } from '../types'

const Container = styled.div`
  box-sizing: border-box;
  color: #333;
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  padding: 2em 2em 2em calc(2em + 70px);
  width: 100%;
`

interface ProjectSectionProps {
  project: Project
}

function ProjectSection(props: ProjectSectionProps) {
  const { project } = props
  const [selectedImage, setSelectedImage] = useState(0)

  const description = (
    <Description
      project={project}
      onImageSelection={(index: number) => setSelectedImage(index)}
      selectedImage={selectedImage}
    />
  )

  return (
    <Section backgroundColor={project.backgroundColor}>
      <Container>
        {project.descriptionPosition === 'LEFT' && description}
        <Viewer project={project} selectedImage={selectedImage} />
        {project.descriptionPosition === 'RIGHT' && description}
      </Container>
    </Section>
  )
}

export default ProjectSection
