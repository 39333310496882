import { Project } from '../types'

import fullSized01 from '../assets/projects/LoupsGarous/loupsGarous_01_full.gif'
import thumbnail01 from '../assets/projects/LoupsGarous/loupsGarous_01_thumb.png'
import thumbnailHover01 from '../assets/projects/LoupsGarous/loupsGarous_01_thumb_hover.png'
import fullSized02 from '../assets/projects/LoupsGarous/loupsGarous_02_full.jpg'
import thumbnail02 from '../assets/projects/LoupsGarous/loupsGarous_02_thumb.png'
import thumbnailHover02 from '../assets/projects/LoupsGarous/loupsGarous_02_thumb_hover.png'
import fullSized03 from '../assets/projects/LoupsGarous/loupsGarous_03_full.jpg'
import thumbnail03 from '../assets/projects/LoupsGarous/loupsGarous_03_thumb.png'
import thumbnailHover03 from '../assets/projects/LoupsGarous/loupsGarous_03_thumb_hover.png'

const project: Project = {
  anchor: 'loupsGarous',
  backgroundColor: '#FFFEB0',
  darkMenuText: false,
  description: `Vous connaissez les Loups Garous de Thiercelieux ? Découvrez Yohannesbourg, petit village tranquille, frappé d’une terrible malédiction.

La nuit, des villageois disparaissent dévorés par des bêtes assoiffées de sang.
Le jour, les coupables désignés à l’unanimité subissent la seule sentence possible : la mort.

Enfin, vous visualisez déjà le principe... A huit ou à douze, le but reste le même : Loups garous ? Villageois ? Qui des 2 gagnera ?`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
  ],
  menuGradientColor: '#333',
  music: {
    label: 'The Brobecks - Die alone',
    link: 'https://youtu.be/EChs3eKvTKs',
    linkColor: '#4A4F6A',
    textHoverColor: 'white',
  },
  tags: ['jeu', 'revisite', 'illustration'],
  title: 'Les Loups Garous de Yohannesbourg',
}

export default project
