import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import mobile from 'is-mobile'

import Home from './pages/Home'
import HomeMobile from './pages/HomeMobile'
import NotFound from './pages/NotFound'

import './App.css'

function App() {
  // @ts-ignore
  const isMobile = mobile()
  return (
    <Router>
      <Switch>
        {isMobile ? (
          <Route path="/" exact component={HomeMobile} />
        ) : (
          <Route path="/" exact component={Home} />
        )}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
