import { Project } from '../types'

import fullSized01 from '../assets/projects/MagazineTupp/magazineTupp_01_full.gif'
import thumbnail01 from '../assets/projects/MagazineTupp/magazineTupp_01_thumb.png'
import thumbnailHover01 from '../assets/projects/MagazineTupp/magazineTupp_01_thumb_hover.png'
import fullSized02 from '../assets/projects/MagazineTupp/magazineTupp_02_full.jpg'
import thumbnail02 from '../assets/projects/MagazineTupp/magazineTupp_02_thumb.png'
import thumbnailHover02 from '../assets/projects/MagazineTupp/magazineTupp_02_thumb_hover.png'
import fullSized03 from '../assets/projects/MagazineTupp/magazineTupp_03_full.jpg'
import thumbnail03 from '../assets/projects/MagazineTupp/magazineTupp_03_thumb.png'
import thumbnailHover03 from '../assets/projects/MagazineTupp/magazineTupp_03_thumb_hover.png'
import fullSized04 from '../assets/projects/MagazineTupp/magazineTupp_04_full.jpg'
import thumbnail04 from '../assets/projects/MagazineTupp/magazineTupp_04_thumb.png'
import thumbnailHover04 from '../assets/projects/MagazineTupp/magazineTupp_04_thumb_hover.png'

const project: Project = {
  anchor: 'catalogueTupperware',
  backgroundColor: '#CCCCCC',
  darkMenuText: false,
  description: `Voici un des catalogues Tupperware sur lequel j'ai travaillé en 2018.
Il s'adresse à tous les employés de la société et propose des cadeaux qui varient en fonction des ventes réalisées, du rôle occupé dans la hiérarchie, mais aussi fait part des prochains divertissements à venir.

J'ai réalisé ce document de la conception à l'impression, sur un thème "indien" moderne avec l'attrape rève et des motifs colorés.

Bref, on voudrait tous des cadeaux comme ça...`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
    {
      fullSized: fullSized04,
      thumbnail: thumbnail04,
      thumbnailHover: thumbnailHover04,
    },
  ],
  menuGradientColor: '#4C0722',
  music: {
    label: `Joe Dassin - L'été indien`,
    link: 'https://youtu.be/589e0KAmM1U',
    linkColor: '#4C0722',
    textHoverColor: 'white',
  },
  tags: ['Print', 'Mise en page', 'Catalogue'],
  title: `Les pépites Tupperware`,
}

export default project
