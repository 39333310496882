import { Project } from '../types'

import fullSized01 from '../assets/projects/Kinkhao/kinkhao_01_full.gif'
import thumbnail01 from '../assets/projects/Kinkhao/kinkhao_01_thumb.png'
import thumbnailHover01 from '../assets/projects/Kinkhao/kinkhao_01_thumb_hover.png'
import fullSized02 from '../assets/projects/Kinkhao/kinkhao_02_full.png'
import thumbnail02 from '../assets/projects/Kinkhao/kinkhao_02_thumb.png'
import thumbnailHover02 from '../assets/projects/Kinkhao/kinkhao_02_thumb_hover.png'
import fullSized03 from '../assets/projects/Kinkhao/kinkhao_03_full.png'
import thumbnail03 from '../assets/projects/Kinkhao/kinkhao_03_thumb.png'
import thumbnailHover03 from '../assets/projects/Kinkhao/kinkhao_03_thumb_hover.png'

const project: Project = {
  anchor: 'kinkhao',
  backgroundColor: '#ABABAB',
  darkMenuText: false,
  description: `Naruto et Luffy débarquent chez Kinkhao : retrouvez vos mangas favoris et plongez dans une ambiance nocturne Tokyo-esque.
  
Des lumières d'enseignes, des néons, un décor coloré et dynamique. Kinkhao vous propose des mets asiatiques très variés, régalez-vous !`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
  ],
  menuGradientColor: '#444E56',
  music: {
    label: `Le voyage de Chihiro - theme`,
    link: 'https://youtu.be/QyOn8VfInVc',
    linkColor: '#F1253C',
    textHoverColor: 'white',
  },
  tags: ['Habillage intérieur', 'Enseigne', 'Stickers'],
  title: 'Kinkhao',
}

export default project
