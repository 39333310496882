import { Project } from '../types'

import fullSized01 from '../assets/projects/PepeManzo/manzo_01_full.gif'
import thumbnail01 from '../assets/projects/PepeManzo/manzo_01_thumb.png'
import thumbnailHover01 from '../assets/projects/PepeManzo/manzo_01_thumb_hover.png'
import fullSized02 from '../assets/projects/PepeManzo/manzo_02_full.jpg'
import thumbnail02 from '../assets/projects/PepeManzo/manzo_02_thumb.png'
import thumbnailHover02 from '../assets/projects/PepeManzo/manzo_02_thumb_hover.png'
import fullSized03 from '../assets/projects/PepeManzo/manzo_03_full.jpg'
import thumbnail03 from '../assets/projects/PepeManzo/manzo_03_thumb.png'
import thumbnailHover03 from '../assets/projects/PepeManzo/manzo_03_thumb_hover.png'
import fullSized04 from '../assets/projects/PepeManzo/manzo_04_full.jpg'
import thumbnail04 from '../assets/projects/PepeManzo/manzo_04_thumb.png'
import thumbnailHover04 from '../assets/projects/PepeManzo/manzo_04_thumb_hover.png'
import fullSized05 from '../assets/projects/PepeManzo/manzo_05_full.jpg'
import thumbnail05 from '../assets/projects/PepeManzo/manzo_05_thumb.png'
import thumbnailHover05 from '../assets/projects/PepeManzo/manzo_05_thumb_hover.png'
import fullSized06 from '../assets/projects/PepeManzo/manzo_06_full.jpg'
import thumbnail06 from '../assets/projects/PepeManzo/manzo_06_thumb.png'
import thumbnailHover06 from '../assets/projects/PepeManzo/manzo_06_thumb_hover.png'
import fullSized07 from '../assets/projects/PepeManzo/manzo_07_full.jpg'
import thumbnail07 from '../assets/projects/PepeManzo/manzo_07_thumb.png'
import thumbnailHover07 from '../assets/projects/PepeManzo/manzo_07_thumb_hover.png'
import fullSized08 from '../assets/projects/PepeManzo/manzo_08_full.jpg'
import thumbnail08 from '../assets/projects/PepeManzo/manzo_08_thumb.png'
import thumbnailHover08 from '../assets/projects/PepeManzo/manzo_08_thumb_hover.png'
import fullSized09 from '../assets/projects/PepeManzo/manzo_09_full.png'
import thumbnail09 from '../assets/projects/PepeManzo/manzo_09_thumb.png'
import thumbnailHover09 from '../assets/projects/PepeManzo/manzo_09_thumb_hover.png'
import fullSized10 from '../assets/projects/PepeManzo/manzo_10_full.jpg'
import thumbnail10 from '../assets/projects/PepeManzo/manzo_10_thumb.png'
import thumbnailHover10 from '../assets/projects/PepeManzo/manzo_10_thumb_hover.png'

const project: Project = {
  anchor: 'pepeManzo',
  backgroundColor: '#2D2D2D',
  darkMenuText: true,
  description: `Le chef Pepe Manzo s'est lissé la moustache et a décidé de se lancer dans le fast-good : voici le premier Pepe Manzo Factory où vous trouverez spécialités italiennes mais pas que !

À St Ouen l'aumône, l'ambiance est conviviale, on y retrouve un mélange d'illustrations à la craie, de photographies en noir et blanc, de matériaux bruts et réconfortants.

Je me suis occupée du début à la fin de ce client, ce qui comprend : menuboards, flyers, cartes menu, cartes livraison, habillage des murs, habillage de la façade et menu extérieur.

Un travail sur un long terme, mais qui demeure passionnant !`,
  descriptionPosition: 'LEFT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
    {
      fullSized: fullSized04,
      thumbnail: thumbnail04,
      thumbnailHover: thumbnailHover04,
    },
    {
      fullSized: fullSized05,
      thumbnail: thumbnail05,
      thumbnailHover: thumbnailHover05,
    },
    {
      fullSized: fullSized06,
      thumbnail: thumbnail06,
      thumbnailHover: thumbnailHover06,
    },
    {
      fullSized: fullSized07,
      thumbnail: thumbnail07,
      thumbnailHover: thumbnailHover07,
    },
    {
      fullSized: fullSized08,
      thumbnail: thumbnail08,
      thumbnailHover: thumbnailHover08,
    },
    {
      fullSized: fullSized09,
      thumbnail: thumbnail09,
      thumbnailHover: thumbnailHover09,
    },
    {
      fullSized: fullSized10,
      thumbnail: thumbnail10,
      thumbnailHover: thumbnailHover10,
    },
  ],
  menuGradientColor: '#FEA443',
  music: {
    label: `La belle et le clochard - La bella Notte`,
    link: 'https://youtu.be/tnKas3fQBr8',
    linkColor: '#FEA443',
    textColor: 'white',
    textHoverColor: '#333',
  },
  tags: ['Habillage intérieur/extérieur', 'Enseigne', 'Menuboards', 'Print'],
  textColor: 'white',
  title: 'Pepe Manzo',
}

export default project
