import { Project } from '../types'

import fullSized01 from '../assets/projects/JdrManoir/jdrManoir_01_full.gif'
import thumbnail01 from '../assets/projects/JdrManoir/jdrManoir_01_thumb.png'
import thumbnailHover01 from '../assets/projects/JdrManoir/jdrManoir_01_thumb_hover.png'
import fullSized02 from '../assets/projects/JdrManoir/jdrManoir_02_full.jpg'
import thumbnail02 from '../assets/projects/JdrManoir/jdrManoir_02_thumb.png'
import thumbnailHover02 from '../assets/projects/JdrManoir/jdrManoir_02_thumb_hover.png'
import fullSized03 from '../assets/projects/JdrManoir/jdrManoir_03_full.png'
import thumbnail03 from '../assets/projects/JdrManoir/jdrManoir_03_thumb.png'
import thumbnailHover03 from '../assets/projects/JdrManoir/jdrManoir_03_thumb_hover.png'

const project: Project = {
  anchor: 'manoirDylehm',
  backgroundColor: '#000000',
  darkMenuText: false,
  description: `"J'ai un dé de six et deux autres de trois.
- Bien, tu pousses la porte et tu entres alors dans une pièce sombre... Que fais-tu ?
- Y a un interrupteur, non ?
- Non. On est au début des années folles, mais c'est un vieux manoir, il n'y a pas l'électricité...
- Une bougie ?
- Tu ne peux pas savoir, il fait tout noir.
- Même pas une allumette sur moi ? Les héros en ont toujours dans les films...
[Le maître du jeu lance un dé]
- Mmm... Ne te rate pas, tu n'en as qu'une seule !
- ...qu'est-ce que je disais, comme dans les films."

Bribe d'une partie de jeu de rôle fort mouvementée.

Dans un jeu de rôle, le maître du jeu raconte une histoire jonchée d'énigmes et de choix décisifs auquels les joueurs doivent faire face. La finalité et le déroulement de leur aventure ne dépend que de cela... Et de nos amis les dés pour la sympathique touche de hasard qui vient bouleverser leurs actions.
Ici, Le Manoir de Dylehm propose d'incarner des personnages qui seraient amenés, par différents évènements, à séjourner dans un manoir hanté dans les années 1920.

Présentations faites, à vous de jouer.`,
  descriptionPosition: 'RIGHT',
  images: [
    {
      fullSized: fullSized01,
      thumbnail: thumbnail01,
      thumbnailHover: thumbnailHover01,
    },
    {
      fullSized: fullSized02,
      thumbnail: thumbnail02,
      thumbnailHover: thumbnailHover02,
    },
    {
      fullSized: fullSized03,
      thumbnail: thumbnail03,
      thumbnailHover: thumbnailHover03,
    },
  ],
  menuGradientColor: '#7E9C9B',
  music: {
    label: 'Avenged Sevenfold - Nightmare',
    link: 'https://youtu.be/94bGzWyHbu0',
    linkColor: '#7EECE5',
    textColor: 'white',
    textHoverColor: 'black',
  },
  tags: ['Jeu', 'Conception', 'Rédaction', 'Illustration'],
  textColor: 'white',
  title: 'Le manoir Dylehm',
}

export default project
